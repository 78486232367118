import React, { useState } from 'react';
import axios from 'axios';
import {
  Checkbox,
  Form,
  Input,
  Message,
  TextArea,
  Button,
} from 'semantic-ui-react';


export const UserWorkshop2024Form = () => {
  // State hooks.
  const [state, setState] = useState({
    submitting: false,
    success: false,
    error: '',
    // Let's not deal with reducers here - too complicated for a form - thus a
    // flat object it is.
    form_data_name: '',
    form_data_affiliation: '',
    form_data_email: '',
    form_data_active_salvus_user: false,
    form_data_lightning_talk_title: '',
    form_data_message: '',
  });

  const resetAndSetSuccess = () => {
    setState({
      ...state,
      submitting: false,
      success: true,
      error: '',
      form_data_name: '',
      form_data_affiliation: '',
      form_data_email: '',
      form_data_active_salvus_user: false,
      form_data_lightning_talk_title: '',
      form_data_message: '',
    });
  };

  const handleChange = (e, { name, value }) => {
    // Manually toggle checkboxes.
    if (name.startsWith('active_salvus_user')) {
      value = !state['form_data_' + name];
    }
    setState({ ...state, ['form_data_' + name]: value });
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const formData = new FormData();
    for (const [key, value] of Object.entries(state)) {
      if (!key.startsWith('form_data_')) {
        continue;
      }
      formData.append(key.substring(10), value);
    }

    setState({ ...state, submitting: true });

    axios({
      method: 'post',
      url: 'https://usebasin.com/f/907ee5c54b62',
      data: formData,
    })
      .then(function(response) {
        resetAndSetSuccess();
      })
      .catch(function(error) {
        if (!error.status) {
          error = 'Network error. Domain cannot be reached.';
        } else {
          error = error.response.data.message;
        }

        setState({
          ...state,
          submitting: false,
          success: false,
          error: error,
        });
      });
  };

  return (
    <Form
      error={state.error.length > 0}
      success={state.success}
      onSubmit={handleOnSubmit}
    >
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          name="name"
          label="Name"
          value={state.form_data_name}
          onChange={handleChange}
          placeholder="Name"
          required
        />
        <Form.Field
          control={Input}
          label="Affiliation"
          name="affiliation"
          placeholder="Company/Institution"
          value={state.form_data_affiliation}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Field
        control={Input}
        label="Email Address"
        name="email"
        type="email"
        placeholder="name@example.com"
        value={state.form_data_email}
        onChange={handleChange}
        required
      />
      <Form.Field
        control={Checkbox}
        label="I am currently an active user of Salvus."
        name="active_salvus_user"
        onChange={handleChange}
        checked={state.form_data_active_salvus_user}
      />
      <Form.Field
        control={Input}
        label="Lightning Talk Title"
        name="lightning_talk_title"
        placeholder={
          'In case you are interested in giving a lighting talk, ' +
          'Please provide a preliminary list of authors and a title.'
        }
        value={state.form_data_lightning_talk_title}
        onChange={handleChange}
      />
      <Form.Field
        control={TextArea}
        name="message"
        label="Message"
        onChange={handleChange}
        value={state.form_data_message}
        placeholder={
          'Any additional information.'
        }
      />
      <Message
        success
        header="Submitted"
        content="Thank you for your interest. We will get back to you shortly."
      />
      <Message error header="Failure." content={`Error: ${state.error}`} />
      <Form.Field
        control={Button}
        content={state.submitting ? 'Submitting ...' : 'Submit'}
        disabled={state.submitting}
        color="violet"
      />
    </Form>
  );
};
