import * as React from 'react';
import { withLayout } from '../../components/layout.tsx';
import { Header, Container } from 'semantic-ui-react';
import { StaticImage } from 'gatsby-plugin-image';

import { UserWorkshop2024Form } from '../../components/salvus_user_day_may_2024_form';

class UserWorkshop2024 extends React.Component<{ data: any }, {}> {
  public render() {
    return (
      <>
        <div
          css={{
            '@media only screen and (max-width: 500px)': {
              marginLeft: '2ex',
              marginRight: '2ex',
            },
          }}
        >
          <Container text textAlign="center">
            <Header
              as="h1"
              css={{
                fontSize: '250% !important',
                marginTop: '0px !important',
                marginBottom: '1em !important',
              }}
            >
              <Header.Content>Online Workshop - Salvus User Day</Header.Content>
            </Header>
            <Header
              as="h2"
              css={{
                fontSize: '150% !important',
                marginTop: '0px !important',
                marginBottom: '1em !important',
              }}
            >
              <Header.Content>Wednesday 15th of May, 2024</Header.Content>
            </Header>
          </Container>
          <Container text textAlign="center" css={{ paddingTop: '2ex' }}>
            <StaticImage
              src="../../images/services/user_day_2024.jpg"
              alt="User Day 2024"
              placeholder="tracedSVG"
              width={300}
            />
          </Container>
          <Container text css={{ paddingTop: '2ex' }}>




            On Wednesday, May 15, 2024 from 3 PM to 5 PM (CEST time),
            we will host a fully online workshop for our users!
          </Container>
          <Container text css={{ paddingTop: '2ex' }}>
            In this workshop, we will showcase the latest developments and new
            features of Salvus that will be included in the
            new <code>2024.1.0</code> release, and help you to upgrade.
          </Container>
          <Container text css={{ paddingTop: '2ex' }}>
            We will start with a general overview and highlight some of the
            recently added features. A selection of applications is presented
            in short lightning talks. Afterwards, short demonstrators of
            different use cases will be presented in breakout sessions. The
            workshop is concluded by a Q&A session which could also be used
            to share advice on upgrading to the new 2024.1.0 release.
          </Container>
          <Container text css={{ paddingTop: '2ex' }}>
            We are looking forward to engaging with our users,
            hearing about your current projects, and compiling a wish list of
            features you would like to see in future updates!
          </Container>
          <Container text css={{ paddingTop: '4ex' }}>
            <Header
              as="h2"
              css={{
                fontSize: '150% !important',
                marginTop: '0px !important',
                marginBottom: '1em !important',
              }}
            >
              <Header.Content>Register here free of charge</Header.Content>
            </Header>
            <UserWorkshop2024Form />
          </Container>
        </div>
      </>
    );
  }
}

export default withLayout(UserWorkshop2024);
